import React from "react";

export default function Navbar() {
  return (
    <div className="nav-contain">
      <svg
        width="284"
        height="65"
        viewBox="0 0 852 196"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M805.972 180.519C803.686 188.997 796.208 196 789.286 196H9.05236C2.1297 196 -1.621 188.997 0.675003 180.519L45.371 15.4173C47.6684 6.93867 55.1404 -5.84126e-06 62.063 -5.84126e-06H842.288C849.211 -5.84126e-06 852.963 6.93867 850.67 15.4173L805.972 180.519Z"
          fill="#0088A8"
        />
        <path
          d="M675.388 97.0187L685.956 63.712C686.43 61.96 685.654 61.3333 684.226 61.3333H627.365C625.934 61.3333 624.393 62.0693 623.917 63.8213L613.349 97.1493C612.873 98.9013 613.649 100 615.08 100H670.606C672.036 100 674.916 98.7707 675.388 97.0187"
          fill="#3FA9F5"
        />
        <path
          d="M777.985 61.3337H754.235H697.373C695.944 61.3337 694.401 62.0697 693.927 63.8204L683.355 97.1484C682.881 98.9017 683.656 100 685.087 100H743.283H767.033C768.463 100 770.008 98.771 770.481 97.019L779.716 63.711C780.189 61.9604 779.415 61.3337 777.985 61.3337Z"
          fill="#ED1C24"
        />
        <path
          d="M95.1514 145.133L117.523 75.7732H82.5154L87.834 59.2838H176.578L171.259 75.7732H136.251L113.879 145.133H95.1514Z"
          fill="white"
        />
        <path
          d="M190.015 145.133C183.895 145.133 178.961 144.609 175.221 143.559C171.479 142.511 168.757 140.832 167.055 138.524C165.349 136.217 164.589 133.196 164.776 129.46C164.957 125.728 165.931 121.133 167.689 115.677L185.88 59.2838H204.609L186.623 115.049C185.756 117.735 185.308 119.939 185.287 121.657C185.26 123.377 185.643 124.784 186.436 125.873C187.225 126.965 188.512 127.72 190.293 128.14C192.075 128.56 194.353 128.769 197.128 128.769H225.16C227.933 128.769 230.348 128.56 232.403 128.14C234.453 127.72 236.227 126.965 237.724 125.873C239.216 124.784 240.513 123.356 241.613 121.595C242.711 119.832 243.693 117.608 244.56 114.923L262.507 59.2838H280.989L262.799 115.677C261.04 121.133 259.047 125.728 256.824 129.46C254.599 133.196 251.891 136.217 248.699 138.524C245.507 140.832 241.699 142.511 237.281 143.559C232.861 144.609 227.551 145.133 221.351 145.133H190.015Z"
          fill="white"
        />
        <path
          d="M365.857 86.0955C366.588 83.8315 366.938 81.9848 366.909 80.5582C366.88 79.1315 366.414 78.0395 365.517 77.2862C364.617 76.5288 363.189 76.0261 361.23 75.7741C359.27 75.5221 356.781 75.3968 353.762 75.3968H308.106L299.661 101.579H345.317C348.336 101.579 350.885 101.454 352.968 101.202C355.048 100.95 356.801 100.446 358.228 99.6901C359.654 98.9355 360.844 97.8461 361.793 96.4181C362.744 94.9928 363.596 93.1048 364.354 90.7541L365.857 86.0955ZM334.45 145.133L295.682 113.914L285.612 145.133H267.128L294.818 59.2848H360.184C366.06 59.2848 370.957 59.6621 374.874 60.4168C378.793 61.1728 381.766 62.5155 383.797 64.4462C385.825 66.3768 386.91 69.0208 387.052 72.3755C387.193 75.7341 386.438 79.9715 384.788 85.0888L382.878 91.0061C381.281 95.9581 379.308 100.05 376.962 103.279C374.613 106.511 371.768 109.069 368.426 110.957C365.081 112.845 361.209 114.167 356.804 114.922C352.398 115.678 347.338 116.055 341.628 116.055H326.938L366.521 145.133H334.45Z"
          fill="white"
        />
        <path
          d="M419.533 74.8936L413.16 94.6563H465.304C468.567 94.6563 470.988 94.1109 472.563 93.0189C474.139 91.9296 475.388 89.9576 476.308 87.1029L478.055 81.6909C478.893 79.0896 478.713 77.3069 477.515 76.3403C476.316 75.3763 473.76 74.8936 469.843 74.8936H419.533ZM408.693 108.502L401.912 129.524H453.935C457.443 129.524 460.108 129.042 461.929 128.076C463.749 127.112 465.093 125.287 465.96 122.6L467.665 117.314C468.801 113.79 468.887 111.442 467.919 110.264C466.951 109.09 464.755 108.502 461.328 108.502H408.693ZM405.961 59.2843H476.345C481.077 59.2843 485.008 59.6203 488.139 60.2923C491.267 60.9656 493.623 62.0749 495.203 63.6283C496.784 65.1803 497.664 67.1963 497.844 69.6683C498.024 72.1456 497.533 75.1883 496.369 78.7963L494.096 85.8443C492.471 90.8789 490.451 94.4883 488.033 96.6696C485.616 98.8536 482.98 100.32 480.125 101.076C481.485 101.412 482.681 101.938 483.719 102.648C484.752 103.363 485.501 104.391 485.967 105.734C486.431 107.078 486.563 108.816 486.363 110.956C486.163 113.096 485.547 115.762 484.517 118.951L482.083 126.503C480.972 129.946 479.568 132.84 477.873 135.188C476.176 137.539 474.052 139.448 471.497 140.916C468.943 142.386 465.883 143.455 462.321 144.126C458.757 144.799 454.612 145.134 449.88 145.134H378.271L405.961 59.2843Z"
          fill="white"
        />
        <path
          d="M582.354 89.6214C583.219 86.9361 583.713 84.7108 583.833 82.9494C583.953 81.1881 583.658 79.7601 582.949 78.6694C582.239 77.5801 581.035 76.8254 579.334 76.4041C577.633 75.9841 575.437 75.7734 572.743 75.7734H542.631C539.694 75.7734 537.239 75.9841 535.269 76.4041C533.297 76.8254 531.603 77.5801 530.193 78.6694C528.778 79.7601 527.606 81.1881 526.67 82.9494C525.734 84.7108 524.833 86.9361 523.967 89.6214L515.807 114.923C514.914 117.692 514.359 119.98 514.145 121.783C513.93 123.588 514.182 125.015 514.906 126.063C515.626 127.112 516.846 127.824 518.559 128.203C520.274 128.58 522.601 128.768 525.538 128.768H555.65C558.342 128.768 560.667 128.58 562.627 128.203C564.585 127.824 566.263 127.112 567.662 126.063C569.059 125.015 570.275 123.588 571.307 121.783C572.337 119.98 573.299 117.692 574.193 114.923L582.354 89.6214ZM519.403 145.133C513.283 145.133 508.35 144.609 504.609 143.559C500.867 142.511 498.146 140.832 496.442 138.524C494.738 136.216 493.978 133.196 494.162 129.46C494.346 125.728 495.319 121.133 497.078 115.677L505.767 88.7401C507.499 83.3708 509.485 78.7961 511.723 75.0188C513.961 71.2428 516.677 68.2028 519.87 65.8934C523.062 63.5854 526.867 61.9081 531.286 60.8588C535.703 59.8108 540.974 59.2841 547.094 59.2841H578.797C584.997 59.2841 589.97 59.8108 593.714 60.8588C597.453 61.9081 600.178 63.5854 601.882 65.8934C603.585 68.2028 604.337 71.2428 604.141 75.0188C603.941 78.7961 602.977 83.3708 601.243 88.7401L592.554 115.677C590.795 121.133 588.802 125.728 586.579 129.46C584.354 133.196 581.646 136.216 578.454 138.524C575.262 140.832 571.454 142.511 567.037 143.559C562.617 144.609 557.307 145.133 551.106 145.133H519.403Z"
          fill="white"
        />
        <path
          d="M818.854 30.3155L783.873 158.594C782.438 163.914 777.673 169.334 774.431 169.334H35.4006C34.4113 169.334 33.6366 168.835 33.0326 167.809C32.0872 166.205 31.8619 163.738 32.4593 161.537L67.4393 33.2635C68.6433 28.8168 72.7926 25.3342 76.8846 25.3342H815.91C817.322 25.3342 818.393 25.7768 818.849 26.5502C819.295 27.3062 819.297 28.6822 818.854 30.3155ZM825.027 21.0862C823.094 17.8062 819.855 16.0008 815.91 16.0008H520.379H391.927H76.8846C69.5886 16.0008 62.418 22.1582 60.21 30.3155L25.2299 158.59C23.8633 163.629 24.4873 169.254 26.854 173.271C28.8753 176.701 31.9899 178.667 35.4006 178.667H387.573H391.927H520.379H774.431C781.875 178.667 788.883 169.769 791.103 161.537L826.086 33.2608C827.326 28.6728 826.95 24.3488 825.027 21.0862Z"
          fill="white"
        />
        <path
          d="M764.652 106.667H614.032C612.601 106.667 611.06 107.403 610.584 109.154L600.015 142.482C599.54 144.235 600.316 145.334 601.745 145.334H753.7C755.129 145.334 756.675 144.104 757.148 142.352L766.383 109.044C766.856 107.294 766.081 106.667 764.652 106.667Z"
          fill="#FCEE21"
        />
      </svg>
    </div>
  );
}
