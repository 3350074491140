import React from "react";

export default function Footer() {
  return (
    <div className="footer-contain">
      <svg
        width="160"
        height="32"
        viewBox="0 0 803 163"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M651.388 81.0187L661.956 47.712C662.43 45.96 661.654 45.3333 660.226 45.3333H603.365C601.934 45.3333 600.393 46.0693 599.917 47.8213L589.349 81.1493C588.873 82.9013 589.649 84 591.08 84H646.606C648.036 84 650.916 82.7707 651.388 81.0187"
          fill="white"
        />
        <path
          d="M753.985 45.3337H730.235H673.373C671.944 45.3337 670.401 46.0697 669.927 47.8204L659.355 81.1484C658.881 82.9017 659.656 84.0004 661.087 84.0004H719.283H743.033C744.463 84.0004 746.008 82.771 746.481 81.019L755.716 47.711C756.189 45.9604 755.415 45.3337 753.985 45.3337Z"
          fill="white"
        />
        <path
          d="M71.1514 129.133L93.5234 59.7732H58.5154L63.834 43.2838H152.578L147.259 59.7732H112.251L89.8794 129.133H71.1514Z"
          fill="white"
        />
        <path
          d="M166.015 129.133C159.895 129.133 154.961 128.609 151.221 127.559C147.479 126.511 144.757 124.832 143.055 122.524C141.349 120.217 140.589 117.196 140.776 113.46C140.957 109.728 141.931 105.133 143.689 99.6772L161.88 43.2838H180.609L162.623 99.0492C161.756 101.735 161.308 103.939 161.287 105.657C161.26 107.377 161.643 108.784 162.436 109.873C163.225 110.965 164.512 111.72 166.293 112.14C168.075 112.56 170.353 112.769 173.128 112.769H201.16C203.933 112.769 206.348 112.56 208.403 112.14C210.453 111.72 212.227 110.965 213.724 109.873C215.216 108.784 216.513 107.356 217.613 105.595C218.711 103.832 219.693 101.608 220.56 98.9225L238.507 43.2838H256.989L238.799 99.6772C237.04 105.133 235.047 109.728 232.824 113.46C230.599 117.196 227.891 120.217 224.699 122.524C221.507 124.832 217.699 126.511 213.281 127.559C208.861 128.609 203.551 129.133 197.351 129.133H166.015Z"
          fill="white"
        />
        <path
          d="M341.857 70.0955C342.588 67.8315 342.938 65.9848 342.909 64.5582C342.88 63.1315 342.414 62.0395 341.517 61.2862C340.617 60.5288 339.189 60.0261 337.23 59.7741C335.27 59.5221 332.781 59.3968 329.762 59.3968H284.106L275.661 85.5795H321.317C324.336 85.5795 326.885 85.4542 328.968 85.2022C331.048 84.9502 332.801 84.4461 334.228 83.6901C335.654 82.9355 336.844 81.8461 337.793 80.4181C338.744 78.9928 339.596 77.1048 340.354 74.7541L341.857 70.0955ZM310.45 129.133L271.682 97.9141L261.612 129.133H243.128L270.818 43.2848H336.184C342.06 43.2848 346.957 43.6621 350.874 44.4168C354.793 45.1728 357.766 46.5155 359.797 48.4462C361.825 50.3768 362.91 53.0208 363.052 56.3755C363.193 59.7341 362.438 63.9715 360.788 69.0888L358.878 75.0061C357.281 79.9581 355.308 84.0501 352.962 87.2795C350.613 90.5115 347.768 93.0688 344.426 94.9568C341.081 96.8448 337.209 98.1675 332.804 98.9221C328.398 99.6781 323.338 100.055 317.628 100.055H302.938L342.521 129.133H310.45Z"
          fill="white"
        />
        <path
          d="M395.533 58.8935L389.16 78.6562H441.304C444.567 78.6562 446.988 78.1109 448.563 77.0189C450.139 75.9295 451.388 73.9575 452.308 71.1029L454.055 65.6909C454.893 63.0895 454.713 61.3069 453.515 60.3402C452.316 59.3762 449.76 58.8935 445.843 58.8935H395.533ZM384.693 92.5015L377.912 113.524H429.935C433.443 113.524 436.108 113.042 437.929 112.076C439.749 111.112 441.093 109.287 441.96 106.6L443.665 101.314C444.801 97.7895 444.887 95.4415 443.919 94.2642C442.951 93.0895 440.755 92.5015 437.328 92.5015H384.693ZM381.961 43.2842H452.345C457.077 43.2842 461.008 43.6202 464.139 44.2922C467.267 44.9655 469.623 46.0749 471.203 47.6282C472.784 49.1802 473.664 51.1962 473.844 53.6682C474.024 56.1455 473.533 59.1882 472.369 62.7962L470.096 69.8442C468.471 74.8789 466.451 78.4882 464.033 80.6695C461.616 82.8535 458.98 84.3202 456.125 85.0762C457.485 85.4122 458.681 85.9375 459.719 86.6482C460.752 87.3629 461.501 88.3909 461.967 89.7335C462.431 91.0775 462.563 92.8162 462.363 94.9562C462.163 97.0962 461.547 99.7615 460.517 102.951L458.083 110.503C456.972 113.946 455.568 116.84 453.873 119.188C452.176 121.539 450.052 123.448 447.497 124.916C444.943 126.386 441.883 127.455 438.321 128.126C434.757 128.799 430.612 129.134 425.88 129.134H354.271L381.961 43.2842Z"
          fill="white"
        />
        <path
          d="M558.354 73.6214C559.219 70.9361 559.713 68.7108 559.833 66.9494C559.953 65.1881 559.658 63.7601 558.949 62.6694C558.239 61.5801 557.035 60.8254 555.334 60.4041C553.633 59.9841 551.437 59.7734 548.743 59.7734H518.631C515.694 59.7734 513.239 59.9841 511.269 60.4041C509.297 60.8254 507.603 61.5801 506.193 62.6694C504.778 63.7601 503.606 65.1881 502.67 66.9494C501.734 68.7108 500.833 70.9361 499.967 73.6214L491.807 98.9228C490.914 101.692 490.359 103.98 490.145 105.783C489.93 107.588 490.182 109.015 490.906 110.063C491.626 111.112 492.846 111.824 494.559 112.203C496.274 112.58 498.601 112.768 501.538 112.768H531.65C534.342 112.768 536.667 112.58 538.627 112.203C540.585 111.824 542.263 111.112 543.662 110.063C545.059 109.015 546.275 107.588 547.307 105.783C548.337 103.98 549.299 101.692 550.193 98.9228L558.354 73.6214ZM495.403 129.133C489.283 129.133 484.35 128.609 480.609 127.559C476.867 126.511 474.146 124.832 472.442 122.524C470.738 120.216 469.978 117.196 470.162 113.46C470.346 109.728 471.319 105.133 473.078 99.6774L481.767 72.7401C483.499 67.3708 485.485 62.7961 487.723 59.0188C489.961 55.2428 492.677 52.2028 495.87 49.8934C499.062 47.5854 502.867 45.9081 507.286 44.8588C511.703 43.8108 516.974 43.2841 523.094 43.2841H554.797C560.997 43.2841 565.97 43.8108 569.714 44.8588C573.453 45.9081 576.178 47.5854 577.882 49.8934C579.585 52.2028 580.337 55.2428 580.141 59.0188C579.941 62.7961 578.977 67.3708 577.243 72.7401L568.554 99.6774C566.795 105.133 564.802 109.728 562.579 113.46C560.354 117.196 557.646 120.216 554.454 122.524C551.262 124.832 547.454 126.511 543.037 127.559C538.617 128.609 533.307 129.133 527.106 129.133H495.403Z"
          fill="white"
        />
        <path
          d="M794.854 14.3155L759.873 142.594C758.438 147.914 753.673 153.334 750.431 153.334H11.4006C10.4113 153.334 9.63658 152.835 9.03258 151.809C8.08725 150.205 7.86193 147.738 8.45926 145.537L43.4393 17.2635C44.6433 12.8168 48.7926 9.33416 52.8846 9.33416H791.91C793.322 9.33416 794.393 9.77683 794.849 10.5502C795.295 11.3062 795.297 12.6822 794.854 14.3155ZM801.027 5.08616C799.094 1.80616 795.855 0.000823498 791.91 0.000823498H496.379H367.927H52.8846C45.5886 0.000823498 38.418 6.15816 36.21 14.3155L1.22993 142.59C-0.136736 147.629 0.487287 153.254 2.85395 157.271C4.87529 160.701 7.98992 162.667 11.4006 162.667H363.573H367.927H496.379H750.431C757.875 162.667 764.883 153.769 767.103 145.537L802.086 17.2608C803.326 12.6728 802.95 8.34883 801.027 5.08616Z"
          fill="white"
        />
        <path
          d="M740.652 90.6671H590.032C588.601 90.6671 587.06 91.4031 586.584 93.1537L576.015 126.482C575.54 128.235 576.316 129.334 577.745 129.334H729.7C731.129 129.334 732.675 128.104 733.148 126.352L742.383 93.0444C742.856 91.2937 742.081 90.6671 740.652 90.6671Z"
          fill="white"
        />
      </svg>
      <hr className="footer-hr" />
      <p className="footer-p">admon@agenciapublicidadelturbo.com</p>
    </div>
  );
}
